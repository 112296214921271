'use client'

import {
  COUNTRY,
  LAST_VISITED_LOCALE,
} from '@susu/headless-commerce/constants/cookie'
import { useCountry } from '@susu/headless-commerce/contexts/country/client'
import { useLocale } from '@susu/headless-commerce/contexts/locale/client'
import {
  getCookie,
  setLastVisitedLocaleCookie,
} from '@susu/headless-commerce/utils/cookies/browser'
import {
  convertCookieFormatToLocale,
  convertLocaleToCookieFormat,
  getCountryFromLocale,
} from '@susu/headless-commerce/utils/localeUtils'
import type { TranslationsByLanguage } from '@susu/i18n'
import { useEffect, useRef } from 'react'
import ClientSwitchLocation from './ClientSwitchLocation'
import { ClientLocationSwitched } from './LocationSwitched/ClientLocationSwitched'

export default function ClientSupportedLocation() {
  const country = useCountry()
  const locale = useLocale()

  const cookieCountry = getCookie(COUNTRY)
  const cookieLastVisitedLocale = getCookie(LAST_VISITED_LOCALE)
  const lastVisitedSet = useRef<boolean>(Boolean(cookieLastVisitedLocale))

  useEffect(() => {
    if (!lastVisitedSet.current) {
      setLastVisitedLocaleCookie(convertLocaleToCookieFormat(locale))
      lastVisitedSet.current = true
    }
  }, [locale])

  return (
    <>
      {cookieCountry &&
        cookieCountry.toLowerCase() !==
          getCountryFromLocale(locale).toLowerCase() && (
          <ClientSwitchLocation />
        )}
      {cookieLastVisitedLocale &&
        convertCookieFormatToLocale(cookieLastVisitedLocale) !== locale && (
          <ClientLocationSwitched country={country} />
        )}
    </>
  )
}
