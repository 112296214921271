'use client'

import { useSignals } from '@preact/signals-react/runtime'
import Icon from '@susu/headless-commerce/components/Icon/Icon'
import type {
  AllowedLocaleCountry,
  Locale,
} from '@susu/headless-commerce/config/locale'
import {
  COUNTRY,
  COUNTRYSWITCH_ACTION_STAY,
} from '@susu/headless-commerce/constants/cookie'
import { useCountries } from '@susu/headless-commerce/contexts/countries/client'
import { useCountry } from '@susu/headless-commerce/contexts/country/client'
import { useLocale } from '@susu/headless-commerce/contexts/locale/client'
import { usePageType } from '@susu/headless-commerce/hooks/usePageType'
import type { ChangeCountryProperties } from '@susu/headless-commerce/libs/avo/avo'
import type { CountryConfiguration } from '@susu/headless-commerce/types/CountryConfiguration'
import {
  getCookie,
  setDenyCountrySwitchCookie,
} from '@susu/headless-commerce/utils/cookies/browser'
import {
  convertLocaleToCountrySwitchCookieFormat,
  createLocale,
  getCountryFromLocale,
  getLanguageFromLocale,
} from '@susu/headless-commerce/utils/localeUtils'
import { pathnameWithoutLocalePrefix } from '@susu/headless-commerce/utils/pathname'
import { URLData } from '@susu/headless-commerce/utils/tracking/segment'
import {
  serializeTrackingCountryName,
  trackEvent,
} from '@susu/headless-commerce/utils/tracking/tracking'
import { useI18N } from '@susu/i18n/client'
import log from '@susu/log'
import { isUndefined } from '@susu/undefined'
import classNames from 'classnames'
import Image from 'next/image'
import { usePathname } from 'next/navigation'
import { useCallback, useMemo, useState } from 'react'
import styles from './LocationBanner.module.scss'

export default function ClientSwitchLocation() {
  useSignals()

  const pageType = usePageType()
  const country = useCountry()
  const locale = useLocale()
  const countries = useCountries()
  const { t } = useI18N()
  const pathname = usePathname()
  const [isCloseButtonClicked, setIsCloseButtonClicked] =
    useState<boolean>(false)

  const cookieCountry: string | undefined = useMemo(() => {
    return getCookie(COUNTRY)
  }, [])

  const countryData: CountryConfiguration | undefined = useMemo(() => {
    if (!country || !cookieCountry) {
      return undefined
    }

    return countries[cookieCountry]
  }, [cookieCountry, country, countries])

  const cookieLocale: Locale | undefined = useMemo(() => {
    if (!cookieCountry || !countryData?.languages) {
      return undefined
    }

    return createLocale(
      countryData.languages[0],
      cookieCountry.toLowerCase() as AllowedLocaleCountry,
    )
  }, [cookieCountry, countryData?.languages])

  const handleSubmit = useCallback(async () => {
    if (isUndefined(countryData?.name) || isUndefined(cookieLocale)) {
      return
    }

    const eventLabel = `${country.name}-${serializeTrackingCountryName(countryData.name)}`
    const { url, locationId } = URLData()

    await trackEvent({
      ga: {
        eventCategory: 'Global_Interactions',
        eventAction: 'Change_Country_Popup',
        eventLabel,
      },
      segment: {
        event: 'changeCountry',
        enrichProps: {
          locale,
          country,
        },
        properties: {
          pageType: pageType as ChangeCountryProperties['pageType'],
          eventLabel:
            `${country.countryCode}_${getCountryFromLocale(cookieLocale)}`.toLowerCase(),
          eventCategory: 'global_interactions',
          eventLocation: 'pop-up',
          url,
          locationId,
        },
      },
    })
  }, [cookieLocale, country, countryData?.name, locale, pageType])

  const handleClose = useCallback(async () => {
    if (isUndefined(cookieCountry)) {
      log.error({
        method: 'ClientSwitchLocation:handleClose',
        message: 'cookieCountry is undefined',
      })
      return
    }

    setIsCloseButtonClicked(true)

    const localeCountry = getCountryFromLocale(locale)

    await setDenyCountrySwitchCookie(
      localeCountry.toUpperCase(),
      cookieCountry.toUpperCase(),
    )
  }, [cookieCountry, locale])

  if (isUndefined(cookieCountry)) {
    log.error({
      method: 'CountrySwitch',
      message: 'cookieCountry is undefined',
    })
    return null
  }

  const localeCountry = getCountryFromLocale(locale)
  const denyCountrySwitchCookie = getCookie(
    `${COUNTRYSWITCH_ACTION_STAY}-${localeCountry.toUpperCase()}-${cookieCountry.toUpperCase()}`,
  )
  const isDenyCountrySwitchCookieSet = Boolean(denyCountrySwitchCookie)

  if (isDenyCountrySwitchCookieSet || isCloseButtonClicked) {
    return null
  }

  if (
    !cookieCountry ||
    !countryData ||
    !cookieLocale ||
    cookieLocale === locale
  ) {
    if (!cookieCountry) {
      log.error(new Error('No cookie country!'))
    }
    if (!countryData) {
      log.error(new Error('No country data!'))
    }
    return null
  }

  const url = new URL(window.location.href)
  url.pathname = `/${cookieLocale}${pathnameWithoutLocalePrefix(pathname)}`

  return (
    <div className={styles['location-banner']}>
      <button
        type={'button'}
        className={styles['location-banner__close-btn']}
        onClick={handleClose}
      >
        <Icon icon="close" />
      </button>
      <div className={styles['location-banner__content']}>
        <div>
          <p className={'body-regular'}>
            {t('switch_lang.shipping_to')} {countryData.name}?
          </p>
          <p
            className={classNames(
              'body-small-regular',
              styles['location-banner__content__text'],
            )}
          >
            {t('switch_lang.update_location')}
          </p>
          <div
            className={classNames(
              styles['location-banner__content__country'],
              'caption-light',
            )}
          >
            <Image
              src={`https://cdn.suitsupply.com/flags/4x3/${countryData.countryCode}.svg`}
              width={16}
              height={14}
              className={styles['location-banner__content__country-flag']}
              alt={'flag'}
            />
            <div>{`${countryData.name} (${countryData.ecommerce.currencyCode})`}</div>
          </div>
        </div>
        <div className={styles['location-banner__content__cta']}>
          <a
            className="body-small-light"
            href={url.toString()}
            onClick={handleSubmit}
          >
            {t('switch_lang.switch_location')}
          </a>
        </div>
      </div>
    </div>
  )
}
