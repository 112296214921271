'use client'

import { useSignal } from '@preact/signals-react'
import { useSignalEffect, useSignals } from '@preact/signals-react/runtime'
import { useLocale } from '@susu/headless-commerce/contexts/locale/client'
import type { CountryConfiguration } from '@susu/headless-commerce/types/CountryConfiguration'
import { setLastVisitedLocaleCookie } from '@susu/headless-commerce/utils/cookies/browser'
import { convertLocaleToCookieFormat } from '@susu/headless-commerce/utils/localeUtils'
import classNames from 'classnames'
import Image from 'next/image'
import { useEffect, useRef } from 'react'

import styles from './LocationSwitched.module.scss'

export type LocationSwitchedProps = {
  country: CountryConfiguration
}

export const ClientLocationSwitched = function ClientLocationSwitched({
  country,
}: LocationSwitchedProps) {
  useSignals()

  const locale = useLocale()
  const visible = useSignal(false)
  const hidden = useSignal(false)
  const contentRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!contentRef.current) {
      return
    }

    requestAnimationFrame(() => {
      visible.value = true
      setTimeout(() => {
        visible.value = false

        contentRef.current?.addEventListener('transitionend', () => {
          hidden.value = true
        })
      }, 2000)
    })
  }, [hidden, visible])

  useSignalEffect(() => {
    if (hidden.value) {
      setLastVisitedLocaleCookie(convertLocaleToCookieFormat(locale))
    }
  })

  return hidden.value ? null : (
    <div className={styles['switched-location']}>
      <div
        className={classNames(styles['switched-location__content'], {
          [styles['switched-location__content-visible']]: visible.value,
        })}
        ref={contentRef}
      >
        <Image
          src={`https://cdn.suitsupply.com/flags/4x3/${country.countryCode}.svg`}
          width={15}
          height={12}
          className={styles['switched-location__flag']}
          alt={'flag'}
        />
        <div className={styles['switched-location__content_txt']}>
          Location changed to{' '}
          <span>
            {country.name} ({country.ecommerce.currencyCode})
          </span>
        </div>
      </div>
    </div>
  )
}
