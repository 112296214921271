import type {
  Breakpoint,
  DeviceData,
  DeviceImageName,
  DeviceName,
} from './ResponsiveImage.types'

// Taken from PRD srcset
export const srcsetWidths = {
  mobile: [375, 480, 768, 828, 1024],
  tablet: [768, 920, 1300, 1440, 1920],
  desktop: [768, 1296, 1728, 2300, 3000, 3500],
}

// Taken from design system defintion
export const breakpoints: Record<Breakpoint, number> = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
}

export const deviceBreakpoints: Record<DeviceImageName, Breakpoint[]> = {
  cloudinaryDesktopImage: ['lg', 'xl', 'xxl'],
  cloudinaryTabletImage: ['md'],
  cloudinaryMobileImage: ['xs', 'sm'],
}

export const devices: Record<DeviceName, DeviceData> = {
  mobile: {
    imageName: 'cloudinaryMobileImage',
    breakpointKeys: deviceBreakpoints.cloudinaryMobileImage,
    breakpointValues: deviceBreakpoints.cloudinaryMobileImage.map(
      (breakpoint) => breakpoints[breakpoint],
    ),
    _srcsetWidths: srcsetWidths.mobile,
  },
  tablet: {
    imageName: 'cloudinaryTabletImage',
    breakpointKeys: deviceBreakpoints.cloudinaryTabletImage,
    breakpointValues: deviceBreakpoints.cloudinaryTabletImage.map(
      (breakpoint) => breakpoints[breakpoint],
    ),
    _srcsetWidths: srcsetWidths.tablet,
  },
  desktop: {
    imageName: 'cloudinaryDesktopImage',
    breakpointKeys: deviceBreakpoints.cloudinaryDesktopImage,
    breakpointValues: deviceBreakpoints.cloudinaryDesktopImage.map(
      (breakpoint) => breakpoints[breakpoint],
    ),
    _srcsetWidths: srcsetWidths.desktop,
  },
}
