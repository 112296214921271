import { computed, signal } from '@preact/signals-react'
import type { AnalyticsBrowser } from '@segment/analytics-next'
import {
  customer,
  customerPromise,
} from '@susu/headless-commerce/contexts/customer'
import type { AvoEnv } from '@susu/headless-commerce/libs/avo/avo'
import { initAvo } from '@susu/headless-commerce/libs/avo/avo'
import { segmentDestination } from '@susu/headless-commerce/libs/segment/avoDestination'
import {
  EVENT_SEGMENT_LOADED,
  IDENTITY_KEY_ANONYMOUS,
  IDENTITY_KEY_KNOWN,
  IDENTITY_VALUE,
} from '@susu/headless-commerce/libs/segment/constants'
import { integrationPlugin } from '@susu/headless-commerce/libs/segment/plugins'
import { analytics } from '@susu/headless-commerce/libs/segment/segment'
import { getOneTrustConsent } from '@susu/headless-commerce/libs/segment/utils'
import {
  getAvoInspectorEnv,
  isBrowser,
} from '@susu/headless-commerce/utils/environment'
import { errorHandler } from '@susu/headless-commerce/utils/errorHandler'
import { pushGA } from '@susu/headless-commerce/utils/tracking/GA'
import { isNotUndefined, isUndefined } from '@susu/undefined'

const libraryLoaded = signal<boolean>(false)
const avoLoaded = signal<boolean>(false)

export const library = signal<AnalyticsBrowser | undefined>(undefined)
export const loaded = computed(() => libraryLoaded.value && avoLoaded.value)

const initialized = signal<boolean>(false)

if (
  isBrowser() &&
  !initialized.value &&
  isNotUndefined(analytics) &&
  isUndefined(library.value)
) {
  analytics.ready(() => {
    ;(async () => {
      try {
        await customerPromise

        const consent = getOneTrustConsent()
        analytics.register(integrationPlugin())

        const identifyTraits = {
          consent_onetrust_web: consent,
          ga_session_id: window?.localStorage?.getItem('session_id') || '',
          ga_client_id: window?.localStorage?.getItem('client_id') || '',
          ga_session_number:
            window?.localStorage?.getItem('session_number') || '',
        }

        if (
          customer.value?.userId &&
          !window?.sessionStorage.getItem(IDENTITY_KEY_KNOWN)
        ) {
          window?.sessionStorage.setItem(IDENTITY_KEY_KNOWN, IDENTITY_VALUE)
          analytics.identify(customer.value?.userId, identifyTraits)
        } else if (!window?.sessionStorage.getItem(IDENTITY_KEY_ANONYMOUS)) {
          window?.sessionStorage.setItem(IDENTITY_KEY_ANONYMOUS, IDENTITY_VALUE)
          analytics.identify(identifyTraits)
        }

        pushGA({ event: EVENT_SEGMENT_LOADED })

        library.value = analytics
        libraryLoaded.value = true
      } catch (error) {
        errorHandler(error as Error)
      }
    })()
  })
  ;(async () => {
    try {
      await customerPromise
      const { AvoInspector } = await import('avo-inspector')

      const inspector = new AvoInspector({
        apiKey: String(process.env.NEXT_PUBLIC_AVO_KEY),
        env: getAvoInspectorEnv(),
        version: '1.0.0',
        appName: 'Suitsupply.com - Headless',
      })

      initAvo(
        {
          env: getAvoInspectorEnv() as AvoEnv,
          inspector,
        },
        {},
        segmentDestination,
      )

      avoLoaded.value = true
    } catch (error) {
      errorHandler(error as Error)
    }
  })()

  initialized.value = true
}
