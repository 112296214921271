import type { SiteId } from '@susu/headless-commerce/gql/generated/graphql'
import type { CloudinaryImage } from '@susu/headless-commerce/types/CloudinaryImage'
import { getSize } from '@susu/headless-commerce/utils/getImageSizefromUrl'
import { isUndefined } from '@susu/undefined'
import type Sizes from 'responsive-image-utils/dist/types/interface/Sizes'

import type {
  Breakpoint,
  DeviceImageName,
  DeviceName,
  DeviceSourceProps,
} from './ResponsiveImage.types'
import { breakpoints, devices } from './config'

export const getClosestBreakpoint = (
  size: number,
  breakpointsRange: Breakpoint[],
): Breakpoint => {
  return breakpointsRange.reduce((prev, curr) => {
    return Math.abs(breakpoints[curr] - size) <
      Math.abs(breakpoints[prev] - size)
      ? curr
      : prev
  })
}

/** Cloudinary specific */
export const sizesString = (sizes: Sizes): string => {
  const stringArr: string[] = []

  for (const breakpoint in sizes) {
    const size = sizes[breakpoint]
    const sizeString =
      breakpoint !== 'xs'
        ? `(min-width: ${breakpoints[breakpoint as Breakpoint]}px) ${size}`
        : size

    stringArr.push(sizeString)
  }

  return stringArr.join(', ')
}

export const smallerBreakpoints = (
  deviceName: DeviceName | 'all',
): Breakpoint[] => {
  if (deviceName === 'all') {
    return Object.keys(breakpoints) as Breakpoint[]
  }

  let smallerDevices: DeviceName[] = []
  let breakpointList: Breakpoint[] = []

  if (deviceName === 'desktop') {
    smallerDevices = ['tablet', 'mobile']
  }

  if (deviceName === 'tablet') {
    smallerDevices = ['mobile']
  }

  for (const device of smallerDevices) {
    breakpointList = [...breakpointList, ...devices[device].breakpointKeys]
  }

  return breakpointList
}

export const deviceSrcSet = (
  image: CloudinaryImage,
  sizes: Sizes,
  deviceName: DeviceName,
): Array<string> => {
  let srcset: Array<string> = []
  const { secure_url, raw_transformation = '', width, height } = image

  const aspectRatio = width / height

  const rawBits = raw_transformation.split('/')
  const usableRawBits = rawBits.filter(
    (bit) => !(bit.includes('w_') || bit.includes('h_')),
  )

  const rightTransformations = ['f_', 'fl_']
  const usableLeft: Array<string> = []
  const usableRight: Array<string> = []

  for (const bit of usableRawBits) {
    if (
      rightTransformations.some((rightTransformation) =>
        bit.startsWith(rightTransformation),
      )
    ) {
      usableRight.push(bit)
    } else {
      usableLeft.push(bit)
    }
  }

  let rightHand = usableRight.length > 0 ? `/${usableRight.join(',')}` : ''

  const reduceFactor = Object.keys(sizes).length - 1

  for (let i = 0; i < Object.keys(sizes).length - reduceFactor; i++) {
    const sizeKey = Object.keys(sizes)[i]

    srcset = [
      ...srcset,
      ...devices[deviceName]._srcsetWidths
        .sort((a, b) => b - a)
        .map((sizewidth) => {
          const sizeWidth = Math.round(
            sizewidth * (Number.parseInt(sizes[sizeKey], 10) / 100),
          )
          const sizeHeight = Math.round(sizeWidth / aspectRatio)
          const leftHand = [
            ...usableLeft,
            `w_${sizeWidth}`,
            `h_${sizeHeight}`,
            'f_auto',
          ]
            .sort()
            .join(',')
          rightHand = rightHand.replace('/f_auto', '') // Remove f_auto as a separate transformation

          const newTransformation = `${leftHand}${rightHand}`
          const newUrl = popVersionParam(
            secure_url.replace(raw_transformation, newTransformation),
          )

          return `${newUrl} ${sizewidth}w`
        }),
    ]
  }

  return srcset
}

export const srcsetAllDevices = (
  image: CloudinaryImage,
  sizes: Sizes,
): Array<string> => {
  const srcsets: Array<string> = []
  const { raw_transformation = '', width, height } = image
  let secure_url = image.secure_url
  const { x, y } = reduceRatio(width, height)
  const aspectRatioString = `${x}:${y}`
  const aspectRatio = width / height

  const rawBits = raw_transformation.split('/')
  const usableRawBits = rawBits.filter(
    (bit) => !(bit.includes('w_') || bit.includes('h_')),
  )

  const rightTransformations = ['f_', 'fl_']
  const usableLeft: Array<string> = []
  const usableRight: Array<string> = []

  for (const bit of usableRawBits) {
    if (
      rightTransformations.some((rightTransformation) =>
        bit.startsWith(rightTransformation),
      )
    ) {
      usableRight.push(bit)
    } else {
      usableLeft.push(bit)
    }
  }

  const rightHand = usableRight.length > 0 ? `/${usableRight.join(',')}` : ''

  const usedWidths = new Set<number>()
  const srcsetWidths = new Set<number>(
    [
      ...devices.mobile._srcsetWidths,
      ...devices.tablet._srcsetWidths,
      ...devices.desktop._srcsetWidths,
    ].sort((a, b) => b - a),
  )

  const breakpointOrder = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']

  // biome-ignore lint/complexity/noForEach: <explanation>
  Object.keys(sizes)
    .sort((a, b) => breakpointOrder.indexOf(b) - breakpointOrder.indexOf(a))
    .forEach((sizeKey) => {
      const keysList = [...srcsetWidths].filter(
        (size) =>
          !usedWidths.has(size) && size >= breakpoints[sizeKey as Breakpoint],
      )

      // biome-ignore lint/complexity/noForEach: <explanation>
      keysList.forEach((size) => {
        const sizeWidth = Math.round(
          size * (Number.parseInt(sizes[sizeKey], 10) / 100) * 1.3,
        )
        const sizeHeight = Math.round((sizeWidth / aspectRatio) * 1.3)

        let leftHand = [
          `ar_${aspectRatioString}`,
          ...usableLeft,
          `w_${sizeWidth}`,
          `h_${sizeHeight}`,
        ].join(',')
        leftHand = orderCloudinaryParameters(leftHand)
        const newTransformation = `${leftHand}${rightHand}`

        // Replace this so it matches warmed up images.
        secure_url = secure_url.replace(
          '/b_rgb:efefef,bo_260px_solid_rgb:efefef,c_pad,w_2400',
          '',
        )
        srcsets.push(
          `${secure_url.replace(raw_transformation, newTransformation)} ${size}w`,
        )

        usedWidths.add(size)
      })
    })

  return srcsets
}

export const srcsetString = (
  image: CloudinaryImage,
  sizes: Sizes,
  deviceName: DeviceName,
): string => {
  if (!Object.keys(devices).includes(deviceName)) {
    throw new Error(`Invalid device name: ${deviceName}`)
  }

  return deviceSrcSet(image, sizes, deviceName).join(',')
}

export const cloudinaryImageFromSrc = (src: string): CloudinaryImage => {
  if (!src) {
    throw new Error('coudinaryImageFromSrc: src must be provided')
  }

  const { width, height } = getSize(src)
  const significantBits = src
    .split('/')
    .filter((part) => part.includes('h_') || part.includes('w_'))
  const baseRawTransformation = significantBits.at(-1) || ''

  const rawTransformation = baseRawTransformation.split(',').join('/')

  return {
    secure_url: src.replace(baseRawTransformation, rawTransformation),
    raw_transformation: rawTransformation,
    width,
    height,
  } as CloudinaryImage
}

const gcd = (a: number, b: number): number => {
  return b === 0 ? a : gcd(b, a % b)
}

export const reduceRatio = (x: number, y: number): { x: number; y: number } => {
  const commonDivisor = gcd(x, y)

  return { x: x / commonDivisor, y: y / commonDivisor }
}

// Order transformations to be ex: h_300,q_80,w_250
export const orderCloudinaryParameters = (leftHand: string) => {
  const tempLeftHand = leftHand.split(',')
  tempLeftHand.splice(
    tempLeftHand.length - 3,
    0,
    tempLeftHand[tempLeftHand.length - 1],
  )
  tempLeftHand.pop()

  return tempLeftHand.join(',')
}

export const parseStaticPattern = (
  pattern: string,
  selection: Selection,
  siteId: SiteId,
) => {
  let selectionEntries = Object.entries(selection)

  if (siteId === 'APAC') {
    selectionEntries = selectionEntries.map(([key, value]) => {
      const adjustedValue = value.endsWith('A') ? value.slice(0, -1) : value
      return [key, adjustedValue]
    })
  }

  const selectionObject = Object.fromEntries(selectionEntries)

  return pattern.replace(/{([^{}]*)}/g, (_match, key) => selectionObject[key])
}

export const getDeviceAndSizesConfig = (
  deviceImageName: DeviceImageName,
  sizes: Sizes,
): { deviceName: DeviceName; sizesValue: string } => {
  let deviceName: DeviceName
  let sizesValue: string

  switch (deviceImageName) {
    case 'cloudinaryMobileImage':
      deviceName = 'mobile'
      sizesValue = sizes.xs || sizes.sm || '100vw'
      break
    case 'cloudinaryTabletImage':
      deviceName = 'tablet'
      sizesValue = sizes.md || '100vw'
      break
    default:
      deviceName = 'desktop'
      sizesValue = sizes.lg || sizes.xl || sizes.xxl || '100vw'
      break
  }

  return { deviceName, sizesValue }
}

export const deviceSource = (
  config: DeviceSourceProps,
): { srcSet: string; sizes: string; deviceName: DeviceName } => {
  const { deviceImageName, sizes, image } = config
  const { deviceName, sizesValue } = getDeviceAndSizesConfig(
    deviceImageName,
    sizes,
  )

  return {
    srcSet: srcsetString(image, sizes, deviceName),
    sizes: sizesValue,
    deviceName: deviceName,
  }
}

export const generateSourcesData = (
  images: Map<DeviceImageName, CloudinaryImage>,
  sizes: Sizes,
) => {
  const namesOrder = [
    'cloudinaryDesktopImage',
    'cloudinaryTabletImage',
    'cloudinaryMobileImage',
  ]
  const sortedImageNames = Array.from(images.keys()).sort(
    (a, b) => namesOrder.indexOf(a) - namesOrder.indexOf(b),
  )
  let { mobileSrcSet = '', tabletSrcSet = '', desktopSrcSet = '' } = {}
  let mobileSizes = ''
  let tabletSizes = ''
  let desktopSizes = ''

  for (const deviceImageName of sortedImageNames) {
    const deviceSources = deviceSource({
      deviceImageName,
      sizes,
      image: images.get(deviceImageName) as CloudinaryImage,
      sortedImageNames: sortedImageNames,
    })

    switch (deviceSources.deviceName) {
      case 'mobile':
        mobileSrcSet = deviceSources.srcSet
        mobileSizes = deviceSources.sizes
        break
      case 'tablet':
        tabletSrcSet = deviceSources.srcSet
        tabletSizes = deviceSources.sizes
        break
      case 'desktop':
        desktopSrcSet = deviceSources.srcSet
        desktopSizes = deviceSources.sizes
        break
      default:
        break
    }
  }

  return {
    desktopSrcSet,
    mobileSrcSet,
    tabletSrcSet,
    desktopSizes,
    mobileSizes,
    tabletSizes,
  }
}

export const generateImageSourcesForCloudinary = (
  images: {
    cloudinaryDesktopImage?: CloudinaryImage[]
    cloudinaryMobileImage?: CloudinaryImage[]
    cloudinaryTabletImage?: CloudinaryImage[]
  },
  sizes: { xs?: string; md?: string; lg?: string },
): {
  desktopSrcSet: string
  mobileSrcSet: string
  tabletSrcSet: string
  desktopSizes: string
  mobileSizes: string
  tabletSizes: string
  smallestUrl: string
} => {
  const usable: Map<DeviceImageName, CloudinaryImage> = new Map()
  const deviceNames = Object.keys(images) as DeviceImageName[]
  // biome-ignore lint/complexity/noForEach: <explanation>
  deviceNames.forEach((deviceName) => {
    if (images[deviceName]?.[0]) {
      usable.set(deviceName, images[deviceName]?.[0] as CloudinaryImage)
    }
  })
  const {
    desktopSrcSet,
    mobileSrcSet,
    tabletSrcSet,
    desktopSizes,
    mobileSizes,
    tabletSizes,
  } = generateSourcesData(usable, sizes)
  const smallestSize = desktopSrcSet.split(', ').pop()
  const [smallestUrl] = smallestSize?.split(' ') ?? ''

  return {
    desktopSrcSet,
    mobileSrcSet,
    tabletSrcSet,
    desktopSizes,
    mobileSizes,
    tabletSizes,
    smallestUrl,
  }
}

/**
 * Utility function used for removing the version parameter from the image url
 * @param url Image url
 * @returns
 */
export const popVersionParam = (url: string | undefined): string => {
  if (isUndefined(url)) {
    return ''
  }
  if (url.indexOf('/v') === -1) {
    return url
  }
  return (
    `${url.split('/v')[0]}/${url.split('/v')[1]?.split('/')?.slice(1)?.join('/')}` ||
    ''
  )
}
